class DiversConfigs {
  id;
  _rates
  _schedules
  constructor(data) {
    this.id = data.id || '';
    this._rates = data.rates
    this._schedules = data.schedules
  }
  get rates() {
    return this._rates
  }
  get schedules() {
    return this._schedules
  }
}
var DataConverter = {
  toFirestore: function() {
    return {}
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id;
    return new DiversConfigs(data)
  }
}
export {DiversConfigs, DataConverter} 

