<template>
    <div>
        <portal to="title-page">
            <h1>Tarifas de Buzos</h1>
        </portal>
        <v-card class="mx-autoelevation-1">
            <v-card-title>
                <v-icon large left>{{mdiPlus}}</v-icon>
                <span class="title font-weight-light">Editar/Actualizar Tarifas</span>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="form" v-model="validForm" lazy-validation>
                    <v-text-field v-model="form.amount1" type="number" :rules="[amountRules.required, /*amountRules.number*/]" 
                    :prepend-icon="mdiCurrencyUsd" class="form-control" required :label="form.nameR1" dense>
                    </v-text-field>
                    <v-text-field v-model="form.amount2" type="number" :rules="[amountRules.required]" :label="form.nameR2" 
                    :prepend-icon="mdiCurrencyUsd" class="form-control" required dense>
                    </v-text-field>
                    <v-text-field v-model="form.amount3" type="number" :rules="[amountRules.required]" :label="form.nameR3" 
                    :prepend-icon="mdiCurrencyUsd" class="form-control" required dense>
                    </v-text-field>
                    <v-btn :disabled="!validForm" @click="validateForm" color="primary" class="btn btn-primary mt-3">
                        <v-icon>{{mdiContentSave}}</v-icon>
                        Guardar
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
            {{snackbar.message}}
            <template v-slot:action="{ attrs }">
                <v-btn color text v-bind="attrs" @click="snackbar.show = false">
                    <v-icon>{{mdiClose}}</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<!-- Creación de las funciones e importaciones para Firebase -->

<script>
    //import { reactive, computed, onMounted } from 'vue'
    import { mdiClose, mdiPencil, mdiCurrencyUsd, mdiText, mdiPlus, mdiContentSave } from '@mdi/js'
    import DiversConfig from '@/models/DiversConfigs.js'

    //--------Script que se llama presionar el boton----------
    export default {

        data() {
            return {
                mdiPencil: mdiPencil,
                mdiClose: mdiClose,
                mdiCurrencyUsd: mdiCurrencyUsd,
                mdiText: mdiText,
                mdiPlus: mdiPlus,
                mdiContentSave: mdiContentSave,
                dataRates: '',   
                DiversConfig: new DiversConfig(),            
                form:{
                    nameR1: 'Precio Cliente:',
                    amount1: '',
                    nameR2: 'Precio Buzo:',
                    amount2: '',
                    nameR3: 'Precio Immersión:',
                    amount3: ''
                },
                validForm: true,
                amountRules: {
                    required: (v) => !!v || 'El monto es requerido',
                    //number: (v) => v.match(/^[0-9]+$/) || 'Introduzca monto válido',
                },
                snackbar:{
                    show:false,
                    color:'',
                    message:''
                },
            }
        },
        methods:{
            async validateForm() {
                if (this.$refs.form.validate()) {
                    console.log('loading');
                    
                    let loading = this.$loading.show();
                    if(this.form.amount1=="" || this.form.amount2=="" || this.form.amount3==""){
                        loading.hide()
                        this.snackbar.show = true;
                        this.snackbar.color = 'red';
                        this.snackbar.message = `No puede haber tarifas vacías`;
                    } else{
                        try{
                            await this.DiversConfig.updateRate(this.dataRates.id, this.form.amount1, this.form.amount2, this.form.amount3)
                            loading.hide()
                            console.log('Éxito¡¡')
                            this.snackbar.show = true;
                            this.snackbar.color = 'green';
                            this.snackbar.message = `Tarifas actualizadas correctamente`;
                        }
                        catch(error) {
                            console.log(error);
                            loading.hide()
                            this.snackbar.show = true;
                            this.snackbar.color = 'red';
                            this.snackbar.message = `Error al actualizar las tarifas, por favor contacte al Administrador si el problema persiste`;
                        }
                    }
                    
                }
            }
        },
            
        created () {
            this.$store.commit('SET_LAYOUT', 'admin')
        },
        mounted() {
            let loading = this.$loading.show()
            this.dataRates = {}
            this.DiversConfig.getConfig().then((snap) => {
                this.dataRates = snap
                this.form.amount1 = this.dataRates._rates.diverPrice
                this.form.amount2 = this.dataRates._rates.customerPrice
                this.form.amount3 = this.dataRates._rates.immersionPrice
                loading.hide()
            })
        }

    }

</script>