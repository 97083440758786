import BaseModel from './BaseModel';
//import Vue from 'vue';
//import lodash from 'lodash';
//import {DataConverter as CategoryExtraDataConverter} from '@/FirestoreDataConverters/CategoryExtra.js';
import {DataConverter/*, CategoryExtra as CategoryExtraData*/} from '@/FirestoreDataConverters/DiversConfigs.js';


export default class DiversConfig extends BaseModel{

    constructor(options) {
        super(options);
    }
    async getConfig() {
        const snap = await this.db.collection('diversConfigs').withConverter(DataConverter).get()
        let config
        snap.forEach(doc => {
            config =  doc.data()
        })
        return config
    }
    updateRate (id, cp, dp, imp){
        return this._db.collection('diversConfigs').doc(id).update(
            {'rates.customerPrice':`${cp}`, 'rates.diverPrice':`${dp}`, 'rates.immersionPrice':`${imp}`}
        )
    }

}

